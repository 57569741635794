import React from 'react'
import {
  Avatar,
  HStack,
  Stack,
  Text,
  IconButton,
  VStack,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import { Button, ButtonSecondary, Message } from '@nucba-coding/nucba-ui'

import { CohortUserRoles } from '@/shared/enums'
import { LinkifyWrapper } from '@/shared/components/LinkifyWrapper'
import { useDeleteCommentMutation } from '../services/assignmentActions.service'
import { useAppDispatch } from '@/shared/app/hooks'
import { assignmentApi } from '../services/assignment.service'
import { Modal } from '@/shared/components/Modal'

type ChatItemProps = {
  message: string
  isSender?: boolean
  isConsecutive?: boolean
  deletedAt?: string
  author: string
  role: CohortUserRoles
  commentId: string
  assignmentId: string
  isStaff: boolean
}

export const ChatItem = ({
  isSender = false,
  isConsecutive = false,
  message,
  author,
  role,
  commentId,
  assignmentId,
  deletedAt,
  isStaff,
}: ChatItemProps) => {
  const dispatch = useAppDispatch()
  const [deleteComment] = useDeleteCommentMutation()

  const handleDelete = async (onClose: () => void) => {
    try {
      await deleteComment({ commentId, assignmentId })
      dispatch(assignmentApi.util.invalidateTags(['Comment']))
      onClose()
    } catch (error) {
      console.error('Failed to delete comment:', error)
    }
  }

  return (
    <Stack spacing={3} align={isSender ? 'flex-end' : 'flex-start'}>
      {!isConsecutive && (
        <HStack
          mt={3}
          ml={isSender ? 0 : 2}
          mr={isSender ? 2 : 0}
          gap={isSender ? 4 : 0}
        >
          <Avatar name={author} borderRadius={13} order={isSender ? 2 : 1} />
          <Stack spacing={0} order={isSender ? 1 : 2}>
            <Text
              fontSize={{ base: '14px', '2xl': 'sm' }}
              fontWeight={600}
              lineHeight={1.4}
              letterSpacing={0.8}
            >
              {author}
            </Text>
            <Text as='span' fontSize='xs' fontWeight={400} letterSpacing={0.5}>
              {role}
            </Text>
          </Stack>
        </HStack>
      )}

      <Message
        boxStyle={{
          w: 'auto',
          maxW: { base: '550px', '2xl': '650px' },
          whiteSpace: 'pre-wrap',
        }}
      >
        <LinkifyWrapper>{message}</LinkifyWrapper>
      </Message>

      {isStaff && isSender && !deletedAt && (
        <Modal
          trigger={
            <IconButton
              aria-label='Delete comment'
              size='sm'
              alignSelf='flex-end'
              mt={1}
              icon={<span>X</span>}
            />
          }
          modalProps={{
            isCentered: true,
          }}
        >
          {({ onClose }) => (
            <ModalContent
              bg='nucba.background'
              maxW='30%'
              padding={4}
              borderRadius={15}
            >
              <ModalHeader fontSize='sm' fontWeight='bold' textAlign='center'>
                Confirmación
              </ModalHeader>
              <ModalBody>
                <Text>
                  ¿Estás seguro de que deseas eliminar este comentario?
                </Text>
              </ModalBody>
              <ModalFooter m='0 auto' mt={1}>
                <ButtonSecondary onClick={onClose}>Cancelar</ButtonSecondary>
                <Button ml={3} onClick={() => handleDelete(onClose)}>
                  Eliminar
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Modal>
      )}
    </Stack>
  )
}
