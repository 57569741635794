import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font,
  StyleSheet,
} from '@react-pdf/renderer'
import { DateTime } from '@/shared/helpers'
import { serializeProductCohort } from '@/inscription/utils'
import { COHORT_TYPE, PRODUCTS_TYPES } from '@/shared/types'

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-Regular_szvrlj.ttf',
      fontWeight: 'regular',
    },
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-SemiBold_cehmjq.ttf',
      fontWeight: 'semibold',
    },
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-Bold_mtmjny.ttf',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'black',
  },
})

type CertificadoPdfProps = {
  user: {
    users: [
      {
        profiles: [
          {
            firstname: string
            lastname: string
            document: string
            addresses: [
              {
                street: string
                number: string
                locality: string
                city: string
                province: string
                country: string
              },
            ]
          },
        ]
        cohort_integrants: [
          {
            product: {
              name: string
              type: string
            }
            cohort: {
              id: string
              code: string
              type: COHORT_TYPE
              startDate: string
              days: number[]
            }
          },
        ]
      },
    ]
  }
}

export const CertificadoPdf = ({ user }: CertificadoPdfProps) => {
  const { users } = user

  const firstUser = users[0]
  const { profiles, cohort_integrants } = firstUser

  const fullName = `${profiles[0].firstname} ${profiles[0].lastname}`
  const document = `${profiles[0].document}`
  const addresses = profiles[0].addresses

  const product = cohort_integrants[0].product.name
  const productType = cohort_integrants[0].product.type.toLowerCase()
  const cohort = cohort_integrants[0].cohort
  const { days, startDate } = serializeProductCohort(cohort)

  const hasValidAddress =
    addresses.length > 0 && Object.values(addresses[0]).some(value => value)


  const capitalizeEachWord = (text: string): string => {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const addressText = hasValidAddress
    ? capitalizeEachWord(
        `con domicilio en ${addresses[0].street || ''} ${addresses[0].number || ''}, ${addresses[0].locality || ''}, ${addresses[0].province || ''}, ${addresses[0].country || ''}`,
      )
    : ''

  const daysText = days ? `los días ${days}` : ''

  const documentText = `Se deja constancia por el mediante documento, que ${fullName} con documento ${document} ${addressText ? `${addressText};` : ''} cursa el ${product} (${
    productType === PRODUCTS_TYPES.ON_DEMAND
      ? 'Ondemand'
      : productType === PRODUCTS_TYPES.CURSO
        ? 'Curso'
        : 'Carrera'
  })
en NUCBA desde el ${startDate} en la modalidad ${cohort.type === COHORT_TYPE.ON_DEMAND ? 'Ondemand' : 'En Vivo'} ${daysText}.`

  return (
    <Document>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <Image
          src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681945993/Galileo-Platform/Assets/logoCertificado_wre3ed.png'
          style={{
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {product}
          </Text>
        </View>

        <View style={{ textAlign: 'center', marginHorizontal: '40px' }}>
          <Text style={{ fontSize: '16px', lineHeight: 1.5 }}>
            {documentText}
          </Text>
        </View>

        <View
          style={{
            width: '700px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '20px',
          }}
        >
          <Image
            src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1710259957/Galileo-Platform/Assets/signatureCeoDark_xn2e5a.png'
            style={{
              width: '100px',
              marginBottom: '10px',
            }}
          />
          <View
            style={{
              width: '180px',
              height: '1px',
              backgroundColor: 'black',
            }}
          ></View>
          <Text
            style={{
              marginTop: '10px',
              fontWeight: 'semibold',
              textAlign: 'center',
            }}
          >
            Luciano D’Amico
          </Text>
          <Text
            style={{
              marginTop: '5px',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            CEO
          </Text>
        </View>
      </Page>
    </Document>
  )
}
