import React from 'react'
import { useParams } from 'react-router-dom'

import { ChatItem } from './ChatItem'
import { EmptyChat } from './EmptyChat'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { Comment } from '@/shared/types'
import { useStudent } from '@/shared/hooks'

type Mentor = {
  author: string
  code: number
}

const mentores: Mentor[] = [
  {
    author: 'Nelson Tugores',
    code: 2017,
  },
  {
    author: 'Rodrigo Troncoso',
    code: 2517,
  },
  {
    author: 'Nahuel Pasquale',
    code: 2417,
  },
  {
    author: 'Rodrigo Troncoso',
    code: 2717,
  },
  {
    author: 'Roberto Bonadeo',
    code: 2617,
  },
  {
    author: 'Salvador Serruya',
    code: 1917,
  },
  {
    author: 'Salvador Serruya',
    code: 2317,
  },
  {
    author: 'Salvador Serruya',
    code: 2317,
  },
  {
    author: 'Rodrigo Troncoso',
    code: 2817,
  },
  {
    author: 'Albano Lucas Kainer',
    code: 3217,
  },
  {
    author: 'Sabrina Zalio',
    code: 3017,
  },
  {
    author: 'VICTOR ZAPATA',
    code: 3117,
  },
]

type AssignmentChatProps = {
  comments: Comment[]
  isIntegrative: boolean
  assignmentId: string
}

export const AssignmentChat = ({
  comments,
  isIntegrative,
  assignmentId,
}: AssignmentChatProps) => {
  const { code } = useParams()
  const { isStaff, firstname, lastname } = useAppSelector(sessionState)
  const { studentId } = useStudent(code!)

  return (
    <>
      {!comments?.length ? (
        <EmptyChat />
      ) : (
        comments.map((comment, index) => {
          const { id, author, senderId, content, deletedAt } = comment

          const isSender = senderId === studentId

          let _author = isSender ? `${firstname} ${lastname}` : author
          const prevSenderId = !!index && comments[index - 1].senderId

          if (!_author) {
            if (isStaff) {
              _author = `${
                comment.cohortIntegrantBySenderid.user!.profiles[0].firstname
              } ${
                comment.cohortIntegrantBySenderid.user!.profiles[0].lastname
              } `
            } else {
              _author = !isIntegrative
                ? 'Nucba Mentoría'
                : mentores.find(mentor => mentor.code === +code!)?.author ?? ''
            }
          }
          return (
            <ChatItem
              key={id}
              message={content}
              isSender={isSender}
              deletedAt={deletedAt}
              isConsecutive={prevSenderId === senderId}
              author={_author}
              role={comment.cohortIntegrantBySenderid.role}
              commentId={comment.id}
              assignmentId={assignmentId}
              isStaff={isStaff}
            />
          )
        })
      )}
    </>
  )
}
