import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { NucCalendar, NucLink } from '@nucba-coding/nucba-ui'

import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { AssignmentChat } from '../components/AssignmentChat'
import { SubmitComment } from '../components/SubmitComment'
import { AboutAssignmentItem } from '../components/AboutAssignmentItem'
import { AssignmentLink } from '../components/AssignmentLink'
import { useGetAssignmentCommentsQuery } from '../services/assignment.service'

import { formatStatus } from '@/education/shared/utils'
import { ASSIGNMENT_STATUS_COLOR } from '@/shared/constants'
import { DateTime } from '@/shared/helpers'
import { ChatLoader } from '@/education/shared/components/loaders/ChatLoader'
import { ListItemsLoader } from '@/education/shared/components/loaders/ListItemsLoader'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

type CommentsState = {
  prev: number | null
  current: number | null
}

export const AssignmentDevolution = () => {
  const { assignmentId } = useParams()

  const { isStaff } = useAppSelector(sessionState)

  const [comments, setComments] = useState<CommentsState>({
    prev: null,
    current: null,
  })

  const endRef = useRef<HTMLDivElement>(null)

  const {
    data: assignment,
    isLoading,
    isSuccess,
  } = useGetAssignmentCommentsQuery({
    assignmentId: assignmentId!,
    isStaff,
  })
  console.log('assignment', assignment)
  useEffect(() => {
    if (comments.prev || (!comments.prev && comments.current)) {
      endRef.current?.scrollIntoView()
    }

    setComments(prevState => ({
      prev: prevState.current,
      current: assignment?.assignment_comments.length ?? null,
    }))
  }, [assignment?.assignment_comments.length])

  return (
    <ContentTemplate mt={{ base: 16, '2xl': 20 }} mb={5}>
      <HStack
        w={{ base: '99%', '2xl': '97%' }}
        align='flex-start'
        justify='space-between'
      >
        <Stack
          w={{ base: '64%', '2xl': '69%' }}
          justify='space-between'
          spacing={8}
        >
          <Stack minH='28vh' spacing={3}>
            {isLoading ? (
              <ChatLoader />
            ) : (
              isSuccess && (
                <AssignmentChat
                  comments={assignment?.assignment_comments}
                  isIntegrative={!!assignment?.exercise.isIntegrative}
                  assignmentId={assignmentId!}
                />
              )
            )}
          </Stack>
          <SubmitComment
            isIntegrative={assignment?.exercise.isIntegrative || false}
          />
        </Stack>
        <Stack w={{ base: '33%', '2xl': '28%' }} justify='space-between'>
          <Stack w='full' spacing={3} fontSize='sm'>
            {isLoading ? (
              <ListItemsLoader />
            ) : (
              <>
                {assignment?.URL &&
                  assignment?.URL.map(url => (
                    <AboutAssignmentItem key={url} icon={<NucLink />} w='100%'>
                      <AssignmentLink
                        text={url}
                        link={url}
                        w='95%'
                        textTransform='none'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                      />
                    </AboutAssignmentItem>
                  ))}

                {assignment?.assignmentStatus_changes &&
                  assignment.assignmentStatus_changes.map(change => (
                    <AboutAssignmentItem key={change.id} icon={<NucCalendar />}>
                      <Text
                        fontSize={{ base: '14px', '2xl': '15px' }}
                        color='#B4B4B4'
                      >
                        {DateTime.createFromDate(
                          new Date(change.updatedAt),
                        ).formatDate()}{' '}
                        -{' '}
                        <Text
                          color={ASSIGNMENT_STATUS_COLOR[change.new]}
                          as='span'
                        >
                          {formatStatus(change.new)}
                        </Text>
                      </Text>
                    </AboutAssignmentItem>
                  ))}
              </>
            )}
          </Stack>
        </Stack>
      </HStack>
      <Box ref={endRef} />
    </ContentTemplate>
  )
}
